import styled from "styled-components";
import bg from "../../../assets/Roadmap/bg.png";
import bg02 from "../../../assets/Roadmap/bg_02.png";
import decor from "../../../assets/Roadmap/roadmap_decor.png";
import fish from "../../../assets/Roadmap/fish_3.png";

export const RoadmapContainer = styled.div`
  position: relative;
  background-color: #63a2dd;
  min-height: 880px;
  z-index: -1;
  &::before {
    content: "";
    position: absolute;
    bottom: -120px;
    left: 0;
    width: 100%;
    height: 641px;
    background: url(${bg}) no-repeat center / 100% 100%;
    pointer-events: none;
  }
  @media screen and (max-width: 767px) {
    min-height: 740px;
    &:before {
      background-size: 100% auto;
      bottom: -310px;
    }
  }
`;
export const RoadmapTitlr = styled.div``;
export const RoadmapWrapper = styled.div`
  padding-top: 150px;
`;
export const RoadmapFish = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  img {
    position: absolute;
    &:nth-child(1) {
      right: 54px;
      top: 40px;
    }
    &:nth-child(2) {
      left: 260px;
      bottom: -100px;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 40px;
    width: 944px;
    height: 594px;
    background: url(${decor}) no-repeat center / 100% auto;
    mix-blend-mode: screen;
  }
`;
export const RoadmapHeading = styled.div`
  text-align: center;
`;
export const RoadmapTitle = styled.h2`
  display: inline-block;
  color: #f5f9ff;
  text-align: center;
  font-family: "Lost-Fish";
  font-size: 36px;
  margin-bottom: 10px;
  position: relative;
  padding-right: 151px;
  padding-left: 151px;
  &:after {
    position: absolute;
    content: "";
    top: 50%;
    margin-top: -133px;
    right: 0;
    background: url(${fish}) center / contain no-repeat;
    width: 174px;
    height: 177px;
  }
  @media screen and (max-width: 767px) {
    font-size: 30px;
    padding-left: 51px;
    padding-right: 51px;
    &:after {
      width: 100px;
      height: 80px;
      margin-top: -63px;
      right: -30px;
    }
  }
`;

export const MapContent = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  max-width: 798px;
  margin: 0 auto -50px;
  width: 100%;
  .map {
    width: 100%;
  }
  .turtie {
    position: absolute;
    pointer-events: none;
    left: calc(50% + -256px);
    top: 115px;
    opacity: 0;
    transition: opacity .26s ease;
    width: 30px;
    img {
      width: 100%;
    }
    &:nth-child(4) {
      left: calc(50% + -190px);
      top: 228px;
    }
    &:nth-child(6) {
      left: calc(50% + -56px);
      top: 242px;
    }
    &:nth-child(8) {
      right: calc(50% + -162px);
      top: 151px;
      left: unset;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 15px;
    .map {
      display: none;
      transition: opacity .26s ease;
      width: 100%;
    }
    .prev,
    .next {
      position: absolute;
      width: 35px;
      top: 50%;
      margin-top: -17px;
      img {
        width: 100%;
      }
    }
    .prev {
      left: 5px;
    }
    .next {
      right: 5px;
      transform: scale(-1)
    }
  }
`;
export const MapDots = styled.div`
  .dot {
    position: absolute;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #94ffb9;
    top: 0;
    border: solid 3px #000;
    cursor: pointer;
    animation: scale 5s linear infinite;
    &:nth-child(1) {
      left: calc(50% + -251px);
      top: 136px;
    }
    &:nth-child(3) {
      left: calc(50% + -188px);
      top: 249px;
      animation-delay: 1s;
    }
    &:nth-child(5) {
      right: calc(50% + 33px);
      top: 261px;
      animation-delay: 2s;
    }
    &:nth-child(7) {
      right: calc(50% + -155px);
      top: 171px;
      animation-delay: 3s;
    }
  }
  @media screen and (max-width: 767px) {
    .dot {
      display: none;
    }
    span {
      width: 8px;
      height: 8px;
      border: solid 1px #000;
      &:nth-child(1) {
        left: calc(41px + 77px);
        top: 70px;
      }
      &:nth-child(2) {
        left: calc(41px + 99px);
        top: 120px;
      }
      &:nth-child(3) {
        left: calc(41px + 171px);
        top: 120px;
      }
      &:nth-child(4) {
        right: calc(41px + 27px);
        top: 79px;
      }
    }
  }
  @keyframes scale {
    0% {
      transform: scale(0.6)
    }
    50% {
      transform: scale(1)
    }
    100% {
      transform: scale(0.6)
    }
  }
`;
export const DetailContent = styled.div`
  position: relative;
  z-index: 1;
  background: url(${bg02}) center / cover no-repeat;
  max-width: 550px;
  min-height: 197px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    background-size: 100% auto;
  }
`;
export const DetailLogo = styled.div`
  pointer-events: none;
  position: absolute;
  left: 0;
  top: -60px;
  width: 150px;
  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    width: 100px;
    right: 0;
    top: -11px;
    img {
      width: 100%;
    }
  }
`;
export const DetailInner = styled.div`
  padding: 28px 88px 28px 52px;
  @media screen and (max-width: 767px) {
    padding-top: 42px;
    padding-right: 49px;
  }
`;
export const DetailTitle = styled.h3`
  color: #104d86;
  font-family: "PP-Pangaia-Bold";
  font-size: 24px;
  font-weight: 700;
  padding-left: 130px;
  margin-bottom: 5px;
  @media screen and (max-width: 767px) {
    font-size: 18px;
    padding-left: 52px;
  }
`;
export const DetailText = styled.p`
  color: #121f2f;
  font-family: "PP-Pangaia-Medium";
  font-size: 16px;
  font-weight: 500;
  @media screen and (max-width: 767px) {
    font-size: 13px;
  }
`;
export const DetailDecor = styled.div`
  position: absolute;
  right: -70px;
  top: -37px;
  width: 150px;
  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    width: 50px;
    right: 0;
    top: 55px;
    img {
      width: 100%;
    }
  }
`;
