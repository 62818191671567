import {
  DetailContent,
  DetailDecor,
  DetailInner,
  DetailLogo,
  DetailText,
  DetailTitle,
  MapContent,
  MapDots,
  RoadmapContainer,
  RoadmapFish,
  RoadmapHeading,
  RoadmapTitle,
  RoadmapWrapper,
} from "./styled";

import { useContext, useState } from "react";
import { ContextProviderWrapper } from "../../../components/Context";

// Image
import fish_1 from "../../../assets/Roadmap/fish_1.png";
import fish_2 from "../../../assets/Roadmap/fish_2.png";
import fish_4 from "../../../assets/Roadmap/fish_4.png";
import map from "../../../assets/Roadmap/map_img_01.png";
import map2 from "../../../assets/Roadmap/map_img_03.png";
import map3 from "../../../assets/Roadmap/map_img_04.png";
import map4 from "../../../assets/Roadmap/map_img_05.png";
import map5 from "../../../assets/Roadmap/map_img_06.png";
import logo from "../../../assets/Roadmap/logo_01.png";
import image01 from "../../../assets/Roadmap/map_img_02.png";
import icon_nav from "../../../assets/Marketplace/button_nav.png";

const Roadmap = () => {
  const [currentStage, setCurrentStage] = useState<any>(1);
  const { isMobile } = useContext(ContextProviderWrapper)!;

  const handleClick = (id: any) => {
    setCurrentStage(id);
  };

  const handleClickPrev = () => {
    if (currentStage !== 1) {
      setCurrentStage(currentStage - 1);
    }
  };
  const handleClickNext = () => {
    if (currentStage < 4) {
      setCurrentStage(currentStage + 1);
    }
  };

  return (
    <RoadmapContainer>
      <RoadmapFish className="as">
        <img src={fish_1} alt="fish" />
        <img src={fish_2} alt="fish" />
      </RoadmapFish>
      <RoadmapWrapper>
        <RoadmapHeading>
          <RoadmapTitle>ROADMAP</RoadmapTitle>
        </RoadmapHeading>
        <MapContent>
          {isMobile && (
            <>
              <div className="prev" onClick={() => handleClickPrev()}>
                <img src={icon_nav} alt="" />
              </div>
              {listMap.map((item: any, index: number) => (
                <img
                  key={index}
                  className="map"
                  src={item.img}
                  alt="Map"
                  style={{
                    display: currentStage === item.id ? "block" : "none",
                  }}
                />
              ))}
              <div className="next">
                <img src={icon_nav} alt="" onClick={() => handleClickNext()} />
              </div>
            </>
          )}
          {!isMobile && (
            <>
              <img className="map" src={map} alt="Map" />
              <MapDots>
                {listDot.map((item: any, index: number) => (
                  <>
                    <span
                      className="dot"
                      onClick={() => handleClick(item.id)}
                    ></span>
                    <img
                      className="turtie"
                      src={fish_4}
                      alt=""
                      style={{ opacity: currentStage === item.id ? "1" : "0" }}
                    />
                  </>
                ))}
              </MapDots>
            </>
          )}
        </MapContent>
        <DetailContent>
          <DetailLogo>
            <img src={logo} alt="" />
          </DetailLogo>
          <DetailInner>
            {RoadmapData.map((item: any, index: number) => (
              <div
                key={index}
                id={item.id}
                style={{ display: currentStage === item.id ? "block" : "none" }}
              >
                <DetailTitle>{item.title}</DetailTitle>
                {/* <DetailText>{item.text}</DetailText> */}
                <DetailText>{item.list.map((item:any, index:any) => {
                      return (
                        <li key={index}>
                          {item}
                        </li>
                      )
                    })}</DetailText>
              </div>
            ))}
          </DetailInner>
          <DetailDecor>
            <img src={image01} alt="" />
          </DetailDecor>
        </DetailContent>
      </RoadmapWrapper>
    </RoadmapContainer>
  );
};

const RoadmapData = [
  {
    id: 1,
    title: "Stage 1",
    text: "Unleash your inner Dino! Bonkki charges into the crypto world, fired up by ancient strength and our tight-knit community. Come be a part of our exciting journey and make a real difference!",
    list: ["Project Idea", "Website Development", "Whitepaper", "Smart Contract Auditing"]
  },
  {
    id: 2,
    title: "Stage 2",
    text: "Unleash your inner Dino! Bonkki charges into the crypto world, fired up by ancient strength and our tight-knit community. Come be a part of our exciting journey and make a real difference!",
    list: ["Community Engagement", "INO on Gate.io", "NFT Sale Round 2 & Whitelist", "Listing DEXs and CEXs"]
  },
  {
    id: 3,
    title: "Stage 3",
    text: "Unleash your inner Dino! Bonkki charges into the crypto world, fired up by ancient strength and our tight-knit community. Come be a part of our exciting journey and make a real difference!",
    list: ["Launching Turtie Marketplace", "Connecting Major NFT Projects", "Media Partnerships", "Launching Turtie Game"]
  },
  {
    id: 4,
    title: "Stage 4",
    text: "Unleash your inner Dino! Bonkki charges into the crypto world, fired up by ancient strength and our tight-knit community. Come be a part of our exciting journey and make a real difference!",
    list: ["Launching Turtie Ecosystem", "Listing on Major CEXs", "Top-tier CEXs Listing", "Building Turtie DEX"]
  },
];

const listDot = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];
const listMap = [
  {
    id: 1,
    img: map2,
  },
  {
    id: 2,
    img: map3,
  },
  {
    id: 3,
    img: map4,
  },
  {
    id: 4,
    img: map5,
  },
];

export default Roadmap;
