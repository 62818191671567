import styled from "styled-components";

export const MessageContainer = styled.div``;
export const MessageTitle = styled.p`
  margin-bottom: 5px;
  color: #000;
  font-size: 16px;
  font-family: "Luckiest Guy", cursive;
`;
export const MessageContent = styled.p``;
export const MessageWrapper = styled.div`
  padding: 10px 20px;
  border-radius: 14px;
`;
export const MessageAssistant = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  color: #3e3e3e;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
  ${MessageWrapper} {
    background-color: #fff;
  }
  img {
    width: 40px;
    height: 34px;
    flex-shrink: 0;
  }
  @media screen and (max-width: 1023px) {
    margin-bottom: 10px;
  }
`;
export const MessageUser = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  flex-direction: row-reverse;
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
  ${MessageTitle} {
    text-align: right;
  }
  ${MessageWrapper} {
    background-color: #fff;
  }
  ${MessageContent} {
    color: #606060;
  }
  img {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
  @media screen and (max-width: 1023px) {
    margin-bottom: 10px;
  }
`;
export const MessageWait = styled.div`
  text-align: center;
  .Typewriter__wrapper {
    font-size: 20px;
    color: #fff;
  }
  @media screen and (max-width: 767px) {
    .Typewriter__wrapper {
      font-size: 15px;
    }
  }
`;
