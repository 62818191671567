import FishAnimation from "../../../components/FishSwimming";
import {
  KeyvisualContainer,
  KeyvisualText,
  KeyvisualTitle,
  KeyvisualWrapper,
} from "./styled";

const Keyvisual = () => {
  return (
    <KeyvisualContainer>
      <FishAnimation top="200px" bottom="unset" />
      <KeyvisualWrapper>
        <KeyvisualTitle>Welcome to Turtie</KeyvisualTitle>
        <KeyvisualText>
          Welcome to the world of Turtie! Get ready to embark on a journey full
          of fun and humor with this adorable icon. Let Turtie into your life
          and together let's create some unforgettable moments!
        </KeyvisualText>
      </KeyvisualWrapper>
      <FishAnimation reverse top="unset" bottom="400px" />
    </KeyvisualContainer>
  );
};

export default Keyvisual;
