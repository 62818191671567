import {
  MarqueeItem,
  PartnerContainer,
  PartnerFish,
  PartnerInner,
  PartnerTitle,
  PartnerTurtle,
  PartnerWrapper,
} from "./styled";
import fish_1 from "../../../assets/Partner/fish_1.png";
import fish_2 from "../../../assets/Partner/fish_2.png";
import partner_1 from "../../../assets/Partner/partner_1.png";
import partner_3 from "../../../assets/Partner/partner_3.png";
import partner_4 from "../../../assets/Partner/partner_4.png";
import partner_5 from "../../../assets/Partner/partner_5.png";
import partner_6 from "../../../assets/Partner/partner_6.png";
import partner_7 from "../../../assets/Partner/partner_7.png";
import partner_8 from "../../../assets/Partner/partner_8.png";
import partner_9 from "../../../assets/Partner/partner_9.png";
import partner_10 from "../../../assets/Partner/partner_10.png";
import partner_11 from "../../../assets/Partner/partner_11.png";
import partner_12 from "../../../assets/Partner/partner_12.png";
import partner_13 from "../../../assets/Partner/partner_13.png";
import partner_14 from "../../../assets/Partner/partner_14.png";
import partner_15 from "../../../assets/Partner/partner_15.png";
import partner_16 from "../../../assets/Partner/partner_16.png";
import partner_17 from "../../../assets/Partner/partner_17.png";
import partner_18 from "../../../assets/Partner/partner_18.png";
import Marquee from "react-fast-marquee";

const Partner = () => {
  return (
    <PartnerContainer>
      <PartnerFish>
        <img src={fish_1} alt="fish" />
        <img src={fish_2} alt="fish" />
      </PartnerFish>
      <PartnerWrapper>
        <PartnerTurtle />
        <PartnerInner>
          <div>
            <PartnerTitle>Partner</PartnerTitle>
            <Marquee direction="right" style={{marginBottom: '5px'}}>
              {partnerData.map((item, index) => {
                return (
                  <MarqueeItem key={index}>
                    <img src={item.img} alt="logo" />
                  </MarqueeItem>
                );
              })}
            </Marquee>
            <Marquee direction="left">
              {partnerData2.map((item, index) => {
                return (
                  <MarqueeItem key={index}>
                    <img src={item.img} alt="logo" />
                  </MarqueeItem>
                );
              })}
            </Marquee>
          </div>
        </PartnerInner>
      </PartnerWrapper>
    </PartnerContainer>
  );
};

const partnerData = [
  // {
  //   img: partner_1,
  // },
  {
    img: partner_3,
  },
  // {
  //   img: partner_4,
  // },
  // {
  //   img: partner_5,
  // },
  // {
  //   img: partner_6,
  // },
  // {
  //   img: partner_7,
  // },
  {
    img: partner_8,
  },
  {
    img: partner_3,
  },
  {
    img: partner_9,
  },
];

const partnerData2 = [
  {
    img: partner_10,
  },
  {
    img: partner_11,
  },
  {
    img: partner_12,
  },
  {
    img: partner_13,
  },
  {
    img: partner_14,
  },
  {
    img: partner_15,
  },
  {
    img: partner_16,
  },
  {
    img: partner_17,
  },
  {
    img: partner_18,
  },
];

export default Partner;
