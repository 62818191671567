import { Link, useLocation } from "react-router-dom";
import { ConnectWalletButton } from "./styled";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { shortenAddress } from "../../../utils/addressUser";

const ConnectWallet = () => {
  const { pathname } = useLocation();
  const { open } = useWeb3Modal();
  const { address } = useAccount();

  const ConnectWallet = () => {
    open();
  };

  return (
    <ConnectWalletButton

    // onClick={() => pathname !== "/" && ConnectWallet()}
    // style={{ width: pathname !== "/" ? "max-content" : "" }}
    >
      <span>
        <a
          href="https://app.ston.fi/swap?chartVisible=false&ft=TON&tt=EQBYuV8ZFWQS992WFGDQEQlDupRKvT5gYeyzY8Lhc9S2uVNn"
          target="_blank"
          rel="noreferrer"
        >
          Buy TUR
        </a>
      </span>
      {/* {pathname !== "/" ? (
        <span>
          {pathname !== "/"
            ? address
              ? shortenAddress(address)
              : "Connect Wallet"
            : "Buy TUR"}
        </span>
      ) : (
        <Link to={"/nfts"}>
          <span>
            {pathname !== "/"
              ? address
                ? shortenAddress(address)
                : "Connect Wallet"
              : "Buy TUR"}
          </span>
        </Link>
      )} */}
    </ConnectWalletButton>
  );
};

export default ConnectWallet;
