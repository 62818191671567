import { SwiperBlock, SwiperWrapper } from "../../Marketplace/styled";
import {
  BottomTreasure,
  BottomTurtle,
  NftsBottom,
  NftsContainer,
  NftsTitle,
  NftsWrapper,
} from "./styled";

//Image
import nft_2 from "../../../assets/Nfts/nft_img_01.png";
import nft_1 from "../../../assets/Marketplace/nft_1.png";
import nft_3 from "../../../assets/Marketplace/nft_3.png";
import { ConnectWalletButton } from "../../../components/Common/ConnectWallet/styled";
import { Link } from "react-router-dom";

function NFTS() {
  return (
    <NftsContainer>
      <NftsWrapper>
        <NftsTitle>THE NFTs</NftsTitle>
        <SwiperWrapper>
          {swiperData.map((item, index) => {
            return (
              <SwiperBlock>
                <figure>
                  <img src={item.img} alt="img" />
                </figure>
              </SwiperBlock>
            );
          })}
        </SwiperWrapper>
        <ConnectWalletButton>
          <Link to={'/nfts'}>Mint now</Link>
        </ConnectWalletButton>
      </NftsWrapper>
      <NftsBottom>
        <BottomTurtle />
        <BottomTreasure />
      </NftsBottom>
    </NftsContainer>
  );
}

const swiperData = [
  {
    img: nft_1,
  },
  {
    img: nft_2,
  },
  {
    img: nft_3,
  },
];

export default NFTS;
