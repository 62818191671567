import React from "react";
import styled, { keyframes, css } from "styled-components";

// Function to generate random numbers
const random = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min);

// Function to generate random timing for animations
const randomT = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min);

// Define keyframes for animations
const moveAnimation = (i: number) => keyframes`
  0% {
    bottom: -100px;
  }
  100% {
    transform: translate(${random(-100, 200)}px, 0);
    opacity: 0;
  }
`;

// Define styled components
const WrapperBubbles = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 200;
`;

interface BubbleProps {
  bgpos: string;
  width: number;
  left: number;
  bottom: number;
  index: number;
  duration: number;
  delay: number;
}

const Bubble = styled.div<BubbleProps>`
  border-radius: 50%;
  opacity: 0.8;
  position: absolute;

  ${({ bgpos, width, left, bottom, index, duration, delay }) => css`
    background-image: url("data:image/svg+xml;utf8, <svg width='100' height='100' xmlns='http://www.w3.org/2000/svg'><circle cx='50' cy='50' r='50' fill='none'/><circle cx='24' cy='24' r='7.5' fill='white' /><path d='M78 58.4999C69.5 72.4999 56 78.4999 53.5 80.9999C51 83.4999 50 89.9999 59.5 87.4999C69 84.9999 82.5 76.4999 86.5 67.4999C90.5 58.5 86.5 44.5 78 58.4999Z' fill='rgba(255,255,255,.4)''/></svg>"),
      radial-gradient(
        circle at 70% 96%,
        white 0.5%,
        rgba(255, 255, 200, 0.4) 2%,
        transparent 9%
      ),
      radial-gradient(
        at 30% 20%,
        rgba(255, 255, 200, 1) 2%,
        transparent 20%,
        transparent 50%,
        rgba(0, 200, 200, 0.3) 73%,
        rgba(0, 80, 80, 0.3) 84%
      ),
      radial-gradient(
        circle at 0% 0%,
        rgba(46, 116, 255, 0.7) 2%,
        transparent 80%
      );
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.4),
      inset 0px 10px 30px 5px rgba(255, 255, 255, 0.34);
    width: ${width}px;
    height: ${width}px;
    left: ${left}vw;
    bottom: ${bottom}vh;
    animation: ${moveAnimation(index)} linear infinite ${duration}s;
  `}
`;

// Define the React component
const BubblesBackground: React.FC = () => {
  const bubbles: JSX.Element[] = [];
  for (let i = 1; i <= 40; i++) {
    const bgpos: string = i % 2 === 0 ? "top right" : "center";
    const width: number = random(15, 80);
    const left: number = random(1, 100);
    const bottom: number = random(1, 100);
    const duration: number = randomT(3, 15);
    const delay: number = 0;
    bubbles.push(
      <Bubble
        key={i}
        index={i}
        bgpos={bgpos}
        width={width}
        left={left}
        bottom={bottom}
        duration={duration}
        delay={delay}
      />
    );
  }

  return <WrapperBubbles>{bubbles}</WrapperBubbles>;
};

export default BubblesBackground;
