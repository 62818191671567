import styled from "styled-components";
import decor from "../../../assets/Nfts/nft_before.png";
import bottom from '../../../assets/Nfts/nft_bottom.png'
import treasure from '../../../assets/Nfts/nft_treasure.png'
import turtle from '../../../assets/Nfts/nft_turtle.png'
import light from '../../../assets/Nfts/nft_light.png'
import { ConnectWalletButton } from "../../../components/Common/ConnectWallet/styled";
import { SwiperBlock, SwiperWrapper } from "../../Marketplace/styled";

// Image
import bgBorder from '../../../assets/Nfts/ntf_border.png'
import bgBorderTop from '../../../assets/Nfts/nft_border_top.png'
import bgBorderBottom from '../../../assets/Nfts/nft_border_bottom.png'
import bgBorderTop02 from '../../../assets/Nfts/nft_border_top_02.png'
import bgBorderBottom02 from '../../../assets/Nfts/nft_border_bottom_02.png'

export const NftsContainer = styled.div`
  min-height: 650px;
  position: relative;
  z-index: 0;
  background-color: #63a2dd;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  &::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    height: 742px;
    background: url(${decor}) no-repeat center / 100% 100%;
    mix-blend-mode: hard-light;
  }
  @media screen and (min-width: 1440px) {
    &::before {
      height: 77vh;
    }
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 40px;
    &:before {
      background-size: cover;
      top: 0;
    }
  }
`;
export const NftsTitle = styled.div`
  color: #F5F9FF;
  text-align: center;
  font-family: "Lost-Fish";
  font-size: 32px;
  margin-bottom: 12px;
`;
export const NftsWrapper = styled.div`
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  ${ConnectWalletButton} {
    color: #003879;
    text-align: center;
    font-family: "Lost-Fish";
    font-size: 20px;
    max-width: 178px;
    width: 100%;
    margin: 30px auto 0;
    span {
      position: relative;
      top: 1px;
    }
  }
  ${SwiperWrapper} {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
    figure {
      max-width: 187px;
      position: relative;
      &::before,
      &::after {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        height: 18px;
        background: center / cover no-repeat;
      }
      &:before {
        top: -2px;
        background-image: url(${bgBorderTop});
      }
      &:after {
        bottom: 0;
        background-image: url(${bgBorderBottom});
      }
    }
    ${SwiperBlock} {
      background-image: url(${bgBorder});
      cursor: pointer;
      transition: transform .26s ease;
      &:hover {
        transform: scale(1.05)
      }
      img {
        border-radius: 14px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    ${ConnectWalletButton} {
      font-size: 13px;
    }
  }
`;
export const NftsBottom = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  &::before,
  &::after {
    content: "";
    position: absolute;
  }
  &::before {
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 830px;
    height: 554px;
    mix-blend-mode: lighten;
    background: url(${light}) no-repeat center / 100% auto;
    z-index: -1;
  }
  &::after {
    background: url(${bottom}) no-repeat center / cover;
    width: 100%;
    height: 193px;
    bottom: 0;
    z-index: -1;
    mix-blend-mode: screen;
  }
  @media screen and (min-width: 1440px) {
    &::after {
      height: 13vw;
    }
  }
`;
export const BottomTurtle = styled.div`
  position: absolute;
  bottom: 0;
  left: 150px;
  background: url(${turtle}) no-repeat center / cover;
  width: 198px;
  height: 254px;
  z-index: 1;
  @media screen and (max-width: 767px) {
    width: 99px;
    height: 127px;
    left: 0;
    background-size: 100% auto;
  }
`;
export const BottomTreasure = styled.div`
  position: absolute;
  bottom: -70px;
  right: -5px;
  background: url(${treasure}) no-repeat center / cover;
  width: 504px;
  height: 418px;
  z-index: 1;
  @media screen and (max-width: 767px) {
    width: 119px;
    height: 127px;
    right: 0;
    bottom: 8px;
  }
`;
