import styled from "styled-components";

export const FishContainer = styled.div`
  pointer-events: none;
  @keyframes wave {
    from {
      background-color: #30457c;
    }
    to {
      background-color: #21315b;
    }
  }

  #fish1 {
    position: absolute;
    top: 200px;
    width: 100%;
    animation: swim 15s linear infinite, swimv 8s ease infinite alternate;
    left: 120%;
    padding: 50px;
  }

  #fish3 {
    position: absolute;
    top: 270px;
    width: 100%;
    animation: swim3 15s 2s linear infinite, swimv 8s 2s ease infinite alternate;
    left: -100%;
    padding: 80px;
  }

  @keyframes swim {
    0% {
      left: 110%;
    }
    100% {
      left: -110%;
    }
  }

  @keyframes swim3 {
    0% {
      left: -110%;
    }
    100% {
      left: 110%;
    }
  }

  @keyframes swimv {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(30px);
    }
    50% {
      transform: translateY(0px);
    }
    75% {
      transform: translateY(50px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  #bubble-1 {
    animation: bubble 5s infinite;
    opacity: 0;
    transform: translate(-20px, -50px);
  }

  #bubble-2 {
    animation: bubble 5s 1s infinite;
    opacity: 0;
    transform: translate(-30px, -40px);
  }

  #bubble-3 {
    animation: bubble 5s 3s infinite;
    opacity: 0;
    transform: translate(-20px, -30px);
  }

  @keyframes bubble {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
    }
  }
`;
