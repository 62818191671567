import styled from "styled-components";

// Image
import bg from "../../../assets/About/about_decor_07.png";

export const AboutContainer = styled.div`
  position: relative;
  z-index: 1;
  margin-top: -160px;
  min-height: 724px;
  &:before {
    z-index: 1;
    position: absolute;
    content: "";
    bottom: -170px;
    left: 0;
    right: 0;
    width: 100%;
    height: 602px;
    background: url(${bg}) no-repeat center / 100% 100%;
    pointer-events: none;
  }
  @media screen and (max-width: 767px) {
    margin-top: 0;
    min-height: unset;
    &::before {
      background-size: 100% auto;
      height: 72px;
      bottom: 0;
    }
  }
`;
export const AboutWrapper = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  min-height: 724px;
  position: relative;
  height: 100%;
  @media screen and (max-width: 767px) {
    min-height: unset;
  }
`;
export const Logo = styled.figure`
  width: 313px;
  margin: 0 auto 8px;
  img {
    width: 100%;
  }
`;
export const AboutTitle = styled.h2`
  color: #70b1e4;
  text-align: center;
  font-family: "Lost-Fish";
  font-size: 48px;
  font-weight: 400;
  margin-bottom: 10px;
`;
export const AboutText = styled.p`
  color: #121f2f;
  text-align: center;
  font-family: "PP-Pangaia-Medium";
  font-size: 16px;
  font-weight: 500;
  max-width: 635px;
  width: 100%;
  margin: 0 auto;
`;
export const AboutImage01 = styled.div`
  max-width: 525px;
  width: 100%;
  margin: 0 auto;
  img {
    width: 100%;
  }
`;
export const AboutDecor01 = styled.div`
  position: absolute;
  top: 410px;
  left: 20px;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
export const AboutDecor02 = styled.div`
  position: absolute;
  top: 340px;
  left: 250px;
  @media screen and (max-width: 767px) {
    top: 389px;
    left: 30px;
    width: 60px;
    img {
      width: 100%;
    }
  }
`;
export const AboutDecor03 = styled.div`
  position: absolute;
  bottom: 140px;
  left: 200px;
`;
export const AboutDecor04 = styled.div`
  position: absolute;
  bottom: 150px;
  right: 280px;
`;
export const AboutDecor05 = styled.div`
  position: absolute;
  top: 160px;
  right: 100px;
  @media screen and (max-width: 767px) {
    width: 80px;
    right: 20px;
    top: 180px;
    img {
      width: 100%;
    }
  }
`;
export const AboutDecor06 = styled.div`
  position: absolute;
  top: 200px;
  right: 50px;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
export const AboutDecor07 = styled.div`
  position: absolute;
  bottom: 20px;
  left: 200px;
`;
export const AboutDecor08 = styled.div`
  position: absolute;
  bottom: 80px;
  right: 280px;
`;
