import styled from "styled-components";
import wave from "../../assets/Wave/wave.svg";

export const WaveContainer = styled.div`
  height: 2%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #62A1DC;

  & > div {
    background: url(${wave}) repeat-x;
    position: absolute;
    width: 6400px;
    height: 198px;
    transform: translate3d(0, 0, 0);
    &:nth-of-type(1) {
      animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
      top: -198px;
    }
    &:nth-of-type(2) {
      top: -175px;
      animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
        swell 7s ease -1.25s infinite;
      opacity: 1;
    }
  }

  @keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }

  @keyframes swell {
    0%,
    100% {
      transform: translate3d(0, -25px, 0);
    }
    50% {
      transform: translate3d(0, 5px, 0);
    }
  }
`;
