import styled from "styled-components";
import bg from "../../assets/Marketplace/marketplace_bg.jpg";
import { AirdropTitle } from "../Airdrop/styled";
import { ConnectWalletButton } from "../../components/Common/ConnectWallet/styled";
import nft_frame from "../../assets/Marketplace/nft_frame.png";

export const MarketplaceContainer = styled.div`
  position: relative;
  overflow: hidden;
  background: url(${bg}) no-repeat center / cover;
  min-height: 100vh;
  #fish3 {
    top: 550px;
  }
`;
export const MarketplaceWrapper = styled.div`
  position: relative;
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
  padding: 150px 15px;
  ${AirdropTitle} {
    margin-bottom: 0;
  }
  @media screen and (max-width: 1100px) {
    width: unset;
  }
  @media screen and (max-width: 767px) {
    padding: 120px 15px;
    .sm {
      display: none;
    }
  }
`;
export const MarketplaceDescribe = styled.p`
  color: #fff;
  text-align: center;
  font-family: "Luckiest Guy", cursive;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 15px;
`;
export const MarketplaceInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  & > div {
    p {
      text-align: center;
      font-size: 18px;
      font-family: "Luckiest Guy", cursive;
      &:nth-child(1) {
        margin-bottom: 5px;
        color: #c9c9c9;
      }
      &:nth-child(2) {
        color: #fff;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
`;
export const MarketplaceSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  ${ConnectWalletButton} {
    font-family: "Lost-Fish";
    font-size: 14px;
    height: 40px;
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    gap: 20px;
  }
`;
export const SearchBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
  border-radius: 11px;
  border: 1px solid #879aff;
  background: rgba(22, 7, 0, 0.2);
  padding: 10px 15px;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(16px);
    pointer-events: none;
  }
  img {
    width: 34px;
    height: 28px;
    mix-blend-mode: luminosity;
  }
  input {
    position: relative;
    outline: none;
    border: none;
    font-size: 16px;
    font-family: "Luckiest Guy", cursive;
    color: #fff;
    background: transparent;
    &::placeholder {
      color: #fff;
    }
  }
`;
export const SwiperWrapper = styled.div`
  position: relative;
  max-width: 920px;
  margin: 0 auto;
  width: 100%;
  .swiper-slide {
    display: flex;
    margin: 0 auto;
    justify-content: center;
  }
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    max-width: 720px;
    margin: 0 auto;
  }
  @media screen and (max-width: 767px) {
    max-width: 400px;
    margin: 0 auto;
  }
`;
export const SwiperBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 284px;
  width: 100%;
  min-height: 330px;
  background: url(${nft_frame}) no-repeat center / 100% auto;
  figure {
    max-width: 194px;
    width: 100%;
    margin-top: 3px;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    max-width: 244px;
    figure {
      max-width: 175px;
    }
  }
`;
export const ButtonNav = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  &.prev {
    left: -80px;
  }
  &.next {
    transform: translateY(-50%) rotate(180deg);
    right: -80px;
  }
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    &.prev {
      left: -40px;
    }
    &.next {
      right: -40px;
    }
  }
  @media screen and (max-width: 767px) {
    z-index: 5;
    max-width: 40px;
    width: 100%;
    img {
      width: 100%;
    }
    &.prev {
      left: -10px;
    }
    &.next {
      right: -10px;
    }
  }
`;
