import styled from "styled-components";
import bg from "../../assets/Mint/mint_bg.jpg";
import bg_count from "../../assets/Mint/mint_counttime.png";
import bg_count_mobile from "../../assets/Mint/mint_counttime_2.png";
import button_light from "../../assets/Mint/button_light.png";
import { ConnectWalletButton } from "../../components/Common/ConnectWallet/styled";
import mint_turtie_1 from "../../assets/Mint/mint_turtie_1.png";
import mint_turtie_2 from "../../assets/Mint/mint_turtie_2.png";
import bg_mint_history from "../../assets/Mint/bg_mint_history.png";
import mint_success from "../../assets/Mint/mint_success.png";
import { SwiperBlock } from "../Marketplace/styled";

export const MintContainer = styled.div`
  position: relative;
  background: url(${bg}) no-repeat center / cover;
  min-height: 100vh;
  overflow: hidden;
  &.blink {
    &::before {
      content: "";
      position: absolute;
      top: -10vh;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(${mint_success}) no-repeat center / cover;
      mix-blend-mode: soft-light;
      animation: blinkMint 1s linear infinite;
      animation-delay: 2.5s;
      opacity: 0;
    }
  }
  @keyframes blinkMint {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
export const MintWrapper = styled.div`
  position: relative;
  max-width: 980px;
  width: 100%;
  min-height: calc(77vh - 110px);
  margin: 0 auto;
  padding: 150px 15px 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.unPreload {
    &::before {
      animation: preloadTurtie 2s linear infinite;
    }
    &::after {
      animation: preloadTurtieRevert 2s linear infinite;
    }
  }
  &.preload {
    &::before,
    &::after {
      animation: translateYTurtie 2s linear infinite;
    }
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
  }
  &::before {
    left: 0;
    bottom: 0;
    width: 330px;
    height: 470px;
    background-image: url(${mint_turtie_1});
    z-index: 2;
  }
  &::after {
    right: 0;
    bottom: 0;
    width: 234px;
    height: 298px;
    background-image: url(${mint_turtie_2});
    z-index: 1;
  }
  @keyframes preloadTurtie {
    0% {
      transform: translate(1000px, 0);
    }
    50% {
      transform: translate(0, 0);
    }
  }
  @keyframes preloadTurtieRevert {
    0% {
      transform: translate(-1000px, 0);
    }
    50% {
      transform: translate(0, 0);
    }
  }
  @keyframes translateYTurtie {
    0% {
      transform: translate(0, 0) rotate(0);
    }
    50% {
      transform: translate(0, -5px) rotate(2deg);
    }
    100% {
      transform: translateY(0, 0) rotate(0);
    }
  }
  @media screen and (max-width: 1023px) {
    width: unset;
    &::before {
      left: 20px;
    }
    &::after {
      right: 20px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 900px) {
    &::before {
      width: 210px;
      height: 305px;
    }
  }
  @media screen and (max-width: 767px) {
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: 80vh;
    &.set {
      /* min-height: 100vh; */
      transition: all .5s ease;
    }
    &::before,
    &::after {
      content: none;
    }
    /* &::before {
      width: 130px;
      height: 175px;
      bottom: 50px;
    }
    &::after {
      width: 124px;
      height: 158px;
    } */
  }
`;
export const MintTitle = styled.div`
  position: relative;
  h2 {
    transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    max-width: 956px;
    width: 100%;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 1023px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
  }
  @media screen and (min-width: 768px) and (max-width: 1250px) {
    h2 {
      max-width: 700px;
    }
  }
`;
export const MintTime = styled.div`
  position: absolute;
  right: -140px;
  bottom: 70px;
  background: url(${bg_count}) no-repeat center / 100% auto;
  width: 298px;
  height: 106px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-delay: 1s;
  p {
    padding-top: 5px;
    padding-left: 15px;
    color: #fff;
    transform: rotate(-5deg);
    font-size: 32px;
    font-family: "Luckiest Guy", cursive;
  }
  @media screen and (min-width: 1024px) and (max-width: 1250px) {
    right: -180px;
    bottom: 40px;
  }
  @media screen and (max-width: 1023px) {
    position: relative;
    right: 0;
    bottom: 0;
    width: 264px;
    height: 84px;
    margin: 0 auto;
    background-image: url(${bg_count_mobile});
    p {
      padding: 0;
      transform: rotate(3deg);
    }
  }
`;
export const MintInfo = styled.div`
  font-family: "Luckiest Guy", cursive;
  color: #003879;
  text-align: center;
  font-size: 40px;
  margin-top: -20px;
  margin-bottom: 10px;
  p {
    transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &:nth-child(1) {
      transition-delay: 1.1s;
    }
    &:nth-child(2) {
      transition-delay: 1.3s;
    }
  }
  @media screen and (max-width: 1023px) {
    margin-bottom: 30px;
    margin-top: 0;
  }
  @media screen and (max-width: 767px) {
    font-size: 25px;
  }
`;
export const MintButton = styled.div`
  cursor: pointer;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-delay: 1.5s;
  display: flex;
  justify-content: center;
  pointer-events: none;
  ${ConnectWalletButton} {
    font-family: "Lost-Fish";
    font-size: 18px;
    pointer-events: auto;
    span {
      padding-top: 7px;
    }
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(${button_light}) no-repeat center / 100% auto;
      mix-blend-mode: plus-lighter;
      filter: blur(0.9px);
    }
  }
  @media screen and (max-width: 767px) {
    ${ConnectWalletButton} {
      width: 150px;
      height: 68px;
    }
  }
`;

/////////////////////////////
export const MintStageWrapper = styled.div`
  width: 100%;
  height: calc(100% - 210px);
  @media screen and (max-width: 767px) {
    width: calc(100% - 20px);
  }
`;
export const MintNftsList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 10px;
  ${SwiperBlock} {
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    figure {
      position: relative;
      min-height: 242px;
      img {
        position: absolute;
        top: -2px;
        left: 0;
        height: 100%;
        object-fit: cover;
        &:nth-child(1) {
          opacity: 0;
          &.visible {
            opacity: 1;
          }
        }
        &:nth-child(2) {
          opacity: 1;
          transition: all 1.5s;
          transition-delay: 1s;
          &.hidden {
            height: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    ${SwiperBlock} {
      max-width: 264px;
      figure {
        min-height: 225px;
        max-width: 185px;
      }
      &:nth-child(1),
      &:nth-child(3) {
        display: none;
      }
    }
  }
`;
export const MintCongrat = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-delay: 3s;
  img {
    width: 100%;
  }
`;
export const MintHistory = styled.div`
  background: url(${bg_mint_history}) no-repeat center / 100% 100%;
  max-width: 275px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 30px;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-delay: 3s;
  h2 {
    color: #213e6b;
    font-family: "Luckiest Guy";
    font-size: 20px;
    text-align: center;
    margin-bottom: 15px;
  }
`;
export const MintHistoryRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
  p {
    font-family: "Luckiest Guy";
    font-size: 17px;
    &:nth-child(1) {
      color: #213e6b;
    }
    &:nth-child(2) {
      color: #fff;
    }
  }
`;
export const MintHistoryBottom = styled.div`
  margin-top: 10px;
  text-align: center;
  p {
    font-family: "Luckiest Guy";
    font-size: 19px;
    &:nth-child(1) {
      color: #fff;
      margin-bottom: 5px;
    }
    &:nth-child(2) {
      color: #213e6b;
      span {
        color: #fff;
      }
    }
  }
`;
