import {
  AirdropAction,
  AirdropContainer,
  AirdropContent,
  AirdropCountTime,
  AirdropInfo,
  AirdropItemTitle,
  AirdropList,
  AirdropTitle,
  AirdropWrapper,
} from "./styled";
import title from "../../assets/Airdrop/airdrop_title.png";
import { ConnectWalletButton } from "../../components/Common/ConnectWallet/styled";

const Airdrop = () => {
  return (
    <AirdropContainer>
      <AirdropWrapper>
        <AirdropTitle>
          <img src={title} alt="title" />
        </AirdropTitle>
        <AirdropList>
          {airdropData.map((item, index) => {
            return (
              <li key={index}>
                <AirdropItemTitle>
                  <h2>{item.title}</h2>
                </AirdropItemTitle>
                <AirdropContent>
                  <h3>{item.subTitle}</h3>
                  <AirdropInfo>
                    <div>
                      <p>Token</p>
                      <p>{item.token}</p>
                    </div>
                    <div>
                      <p>Total Token</p>
                      <p>{item.totalToken}</p>
                    </div>
                    <div>
                      <p>Participants</p>
                      <p>{item.participants}</p>
                    </div>
                  </AirdropInfo>
                </AirdropContent>
                <AirdropAction>
                  <AirdropCountTime>0d:00h:00m:00</AirdropCountTime>
                  {/* <AirdropCountTime>2s:23h:28m:29</AirdropCountTime> */}
                  <ConnectWalletButton>
                    <span>View</span>
                  </ConnectWalletButton>
                </AirdropAction>
              </li>
            );
          })}
        </AirdropList>
      </AirdropWrapper>
    </AirdropContainer>
  );
};

const airdropData = [
  {
    title: "Up comming",
    subTitle: "Join my coin airdrop",
    token: 0,
    totalToken: 0,
    participants: 0,
  },
  {
    title: "Up comming",
    subTitle: "Join my coin airdrop",
    token: 0,
    totalToken: 0,
    participants: 0,
  },
  {
    title: "Up comming",
    subTitle: "Join my coin airdrop",
    token: 0,
    totalToken: 0,
    participants: 0,
  },
];

export default Airdrop;
