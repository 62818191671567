import { useContext, useEffect, useState } from "react";
import title from "../../assets/Mint/mint_title.png";
import { ConnectWalletButton } from "../../components/Common/ConnectWallet/styled";
import {
  MintButton,
  MintCongrat,
  MintContainer,
  MintHistory,
  MintHistoryBottom,
  MintHistoryRow,
  MintInfo,
  MintNftsList,
  MintStageWrapper,
  MintTime,
  MintTitle,
  MintWrapper,
} from "./styled";
import nft_2 from "../../assets/Marketplace/nft_2.png";
import nft_1 from "../../assets/Marketplace/nft_1.png";
import nft_3 from "../../assets/Marketplace/nft_3.png";
import { SwiperBlock } from "../Marketplace/styled";
import mint_congrat from "../../assets/Mint/mint_congrat.png";
import mint_turtie_dummy from "../../assets/Mint/mint_turtie_dummy.png";
import { ContextProviderWrapper } from "../../components/Context";

const Nfts = () => {
  const {isMobile} = useContext(ContextProviderWrapper)!
  const [preload, setPreload] = useState(false);
  const [turtieSurf, setTurtieSurf] = useState(false);
  useEffect(() => {
    setPreload(!preload);
    setTimeout(() => {
      setTurtieSurf(true);
    }, 1000);
  }, []);

  // Mint stage
  const [mintStage, setMintStage] = useState(false);
  const [frameCombine, setFrameCombine] = useState(false);

  return (
    <MintContainer className={frameCombine ? "blink" : ""}>
      <MintWrapper className={turtieSurf && !isMobile ? "preload" : frameCombine && isMobile ? "set" : "unPreload"}>
        {/* Unmint stage */}
        <div
          style={{
            opacity: mintStage ? "0" : "1",
            visibility: mintStage ? "hidden" : "visible",
            pointerEvents: mintStage ? "none" : "auto",
            transition: "all .3s ease-in-out",
          }}
        >
          <MintTitle>
            <h2
              style={{
                transform: preload ? "scale(1)" : "scale(0)",
              }}
            >
              <img src={title} alt="title" />
            </h2>
            <MintTime
              style={{
                transform: preload ? "scale(1)" : "scale(0)",
              }}
            >
              <p>11d:12h:47m:29</p>
            </MintTime>
          </MintTitle>
          <MintInfo>
            <p
              style={{
                transform: preload ? "scale(1)" : "scale(0)",
              }}
            >
              Qty: 120 nfts
            </p>
            <p
              style={{
                transform: preload ? "scale(1)" : "scale(0)",
              }}
            >
              PRICE: 20 TON
            </p>
          </MintInfo>
          <MintButton
            onClick={() => {
              setMintStage(true);
              setTimeout(() => {
                setFrameCombine(true);
              }, 1500);
            }}
            style={{
              opacity: preload ? "1" : "0",
            }}
          >
            <ConnectWalletButton>
              <span>Mint demo</span>
            </ConnectWalletButton>
          </MintButton>
        </div>

        {/* Mint stage */}
        <MintStageWrapper
          style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            opacity: mintStage ? "1" : "0",
            visibility: mintStage ? "visible" : "hidden",
            pointerEvents: mintStage ? "auto" : "none",
            transition: "all 1s ease-in-out",
          }}
        >
          <MintCongrat
            style={{
              opacity: mintStage ? "1" : "0",
            }}
          >
            <img src={mint_congrat} alt="congrat" />
          </MintCongrat>
          <MintNftsList>
            {swiperData.map((item, index) => {
              return (
                <SwiperBlock
                  key={index}
                  style={{
                    transform:
                      index === 0 && frameCombine
                        ? "translateX(314px)"
                        : index === 2 && frameCombine
                        ? "translateX(-314px)"
                        : "",
                    visibility:
                      index === 0 && frameCombine
                        ? "hidden"
                        : index === 2 && frameCombine
                        ? "hidden"
                        : "visible",
                    pointerEvents:
                      index === 0 && frameCombine
                        ? "none"
                        : index === 2 && frameCombine
                        ? "none"
                        : "auto",
                  }}
                >
                  <figure>
                    {/* Ảnh mint thành công để ở đây */}
                    <img
                      className={frameCombine ? "visible" : ""}
                      src={item.img}
                      alt="img"
                    />
                    {/* ---------------------------- */}
                    <img
                      className={frameCombine ? "hidden" : ""}
                      src={mint_turtie_dummy}
                      alt="img"
                    />
                  </figure>
                </SwiperBlock>
              );
            })}
          </MintNftsList>
          <MintHistory
            style={{
              opacity: mintStage ? "1" : "0",
              visibility: mintStage ? "visible" : "hidden",
              pointerEvents: mintStage ? "auto" : "none",
            }}
          >
            <h2>History</h2>
            <div>
              <MintHistoryRow>
                <p>Time</p>
                <p>Jan, 19th, 2024</p>
              </MintHistoryRow>
              <MintHistoryRow>
                <p>By</p>
                <p>0x323...d94</p>
              </MintHistoryRow>
              <MintHistoryRow>
                <p>Type</p>
                <p>Turtie</p>
              </MintHistoryRow>
            </div>
            <MintHistoryBottom>
              <p>Price</p>
              <p>
                20 TON
                <span>/NFT</span>
              </p>
            </MintHistoryBottom>
          </MintHistory>
        </MintStageWrapper>
      </MintWrapper>
    </MintContainer>
  );
};

const swiperData = [
  {
    img: nft_1,
  },
  {
    img: nft_2,
  },
  {
    img: nft_3,
  },
];

export default Nfts;
