import styled from "styled-components";

export const AppContainer = styled.div<{ home?: boolean }>`
  min-height: 100vh;
  background-color: #f5f9ff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  @media screen and (max-width: 767px) {
    ::-webkit-scrollbar {
      width: 0;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
  }
`;
