import styled from "styled-components";
import bg from "../../assets/Ai/ai_bg.jpg";
import { AirdropTitle } from "../Airdrop/styled";

export const ChatContainer = styled.div`
  position: relative;
  background: url(${bg}) no-repeat center / cover;
  min-height: 100vh;
  #fish3 {
    top: 550px;
  }
`;
export const ChatWrapper = styled.div`
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
  padding: 150px 15px;
  font-family: "PP-Pangaia";
  ${AirdropTitle} {
    position: relative;
    margin-bottom: -30px;
    z-index: 5;
  }
  @media screen and (max-width: 767px) {
    width: unset;
  }
`;
export const AiEmpty = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  .Typewriter__wrapper {
    font-size: 30px;
    color: #fff;
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    .Typewriter__wrapper {
      font-size: 25px;
    }
  }
  @media screen and (max-width: 767px) {
    top: 60%;
  }
`;
export const AiChatBot = styled.div`
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border: 2px solid #fff;
  background: rgba(0, 0, 0, 0.28);
  backdrop-filter: blur(24px);
  padding: 20px;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: calc(100% - 70px);
  }
  @media screen and (max-width: 767px) {
    width: calc(100% - 44px);
  }
`;
export const AiTitle = styled.h2`
  color: #000;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
`;
export const ChatBotWrapper = styled.div<{ message?: boolean }>`
  margin: 0 auto;
  width: 100%;
  position: relative;
  border-radius: 20px;
`;
export const ChatBotTitle = styled.h2`
  color: #000;
  text-align: center;
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 10px;
  span {
    color: #97de00;
  }
  @media screen and (max-width: 767px) {
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 10px;
  }
`;
export const ChatBotWindow = styled.div<{ message: boolean }>`
  overflow-y: auto;
  margin-bottom: 30px;
  padding: 0 10px;
  height: calc(80vh - 350px);
  @media screen and (max-width: 1023px) {
    margin-bottom: 10px;
    padding: 0;
    height: unset;
    min-height: 50vh;
  }
`;
export const ChatBotHeader = styled.div``;
export const ChatBotLogo = styled.div`
  text-align: center;
  img {
    margin: 0 auto;
  }
`;
export const HistoryList = styled.ul`
  margin-bottom: 20px;
  max-height: 500px;
  overflow-y: auto;
  @media screen and (max-width: 1023px) {
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    max-height: 300px;
  }
`;
export const ChatBotEmpty = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  @media screen and (max-width: 767px) {
    font-size: 17px;
    text-align: center;
  }
`;
