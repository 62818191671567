import {
  AboutContainer,
  AboutDecor01,
  AboutDecor06,
  AboutImage01,
  AboutText,
  AboutTitle,
  AboutWrapper,
  Logo,
} from "./styled";

// Image
import AboutLogo from "../../../assets/About/About_logo.png";
import AboutImage1 from "../../../assets/About/about_img_01.png";
import Decor01 from "../../../assets/About/about_decor_01.png";
import Decor06 from "../../../assets/About/about_decor_06.png";
import Fish01 from "./Fish01";
import Fish02 from "./Fish02";
import WavesAnimation from "../../../components/Wave";
import { useContext } from "react";
import { ContextProviderWrapper } from "../../../components/Context";

const About = () => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  return (
    <AboutContainer>
      {isDesktop && <WavesAnimation />}
      <AboutWrapper>
        <Logo>
          <img src={AboutLogo} alt="" />
        </Logo>
        <AboutTitle>About</AboutTitle>
        <AboutText>
          Within our vibrant underwater ecosystem, you'll experience not only
          creating and collecting unique NFT artworks but also engaging with
          artificial intelligence through AI chat. Additionally, you'll
          participate in exclusive airdrop programs, offering great
          opportunities to engage with the community and receive valuable
          rewards. Join us in exploring a world full of creativity and
          potential!
        </AboutText>
        <AboutImage01>
          <img src={AboutImage1} alt="" />
        </AboutImage01>
        <AboutDecor01>
          <img src={Decor01} alt="" />
        </AboutDecor01>
        <Fish01 />
        <Fish02 />
        <AboutDecor06>
          <img src={Decor06} alt="" />
        </AboutDecor06>
      </AboutWrapper>
    </AboutContainer>
  );
};

export default About;
