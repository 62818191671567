import About from "./About";
import Keyvisual from "./Keyvisual";
import NFTS from "./NFTS";
import Partner from "./Partner";
import Roadmap from "./Roadmap";
import Tokenomic from "./Tokenomic";

const Home = () => {
  return (
    <>
      <Keyvisual/>
      <About/>
      <NFTS/>
      <Roadmap />
      <Tokenomic />
      <Partner />
    </>
  )
};

export default Home;
