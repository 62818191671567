import styled from "styled-components";

// Image
import footerBg from "../../assets/Footer/footer_bg.png";
import buttonBg from "../../assets/Common/ConnectWallet/button_bg.png";
import { SwiperBlock, SwiperWrapper } from "../../page/Marketplace/styled";

// Image
import bgBorder from '../../assets/Nfts/ntf_border.png'
import bgBorderTop from '../../assets/Nfts/nft_border_top.png'
import bgBorderBottom from '../../assets/Nfts/nft_border_bottom.png'
import bgBorderTop02 from '../../assets/Nfts/nft_border_top_02.png'
import bgBorderBottom02 from '../../assets/Nfts/nft_border_bottom_02.png'

export const FooterContainer = styled.div`
  background: url(${footerBg}) no-repeat center / cover ;
  min-height: 516px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 50px;
  @media screen and (min-width: 1440px) {
    background-size: 100% 100%;
  }
  @media screen and (max-width: 767px) {
    background-color: #63a2dd;
    padding-top: 50px;
  }
`;
export const FooterWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  @media screen and (max-width: 767px) {
    padding: 0 15px;
  }
  ${SwiperWrapper} {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
    figure {
      max-width: 187px;
      position: relative;
      &::before,
      &::after {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        height: 18px;
        background: center / cover no-repeat;
      }
      &:before {
        top: -2px;
        background-image: url(${bgBorderTop});
      }
      &:after {
        bottom: 0;
        background-image: url(${bgBorderBottom});
      }
    }
    ${SwiperBlock} {
      background-image: url(${bgBorder});
      transition: transform .26s ease;
      cursor: pointer;
      &:hover {
        transform: scale(1.05)
      }
      img {
        border-radius: 14px;
      }
    }
  }
`;
export const ListSocial = styled.ul`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  margin-bottom: 7px;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    padding: 0 50px;
    gap: 20px;
  }
`;
export const ItemSocial = styled.li`
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 108px;
    height: 49px;
    background: url(${buttonBg}) center / cover no-repeat;
  }
  img {
    display: block;
    width: 28px;
    position: relative;
    top: -3px;
  }

`;
export const Small = styled.small`
  display: block;
  color: #fff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  text-align: center;
`;
