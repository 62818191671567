import {
  MessageAssistant,
  MessageContainer,
  MessageContent,
  MessageTitle,
  MessageUser,
  MessageWrapper,
} from "./styled";
import user from "../../../assets/Ai/user.png";
import assistant from "../../../assets/Ai/assistant.png";

export default function Message({ role, content }: any) {
  return (
    <MessageContainer>
      {role === "assistant" ? (
        <MessageAssistant>
          <img src={assistant} alt="assistant" />
          <MessageWrapper>
            <MessageTitle>Turtie</MessageTitle>
            <MessageContent>{content}</MessageContent>
          </MessageWrapper>
        </MessageAssistant>
      ) : (
        <>
          <MessageUser>
            <img src={user} alt="user" />
            <MessageWrapper>
              <MessageTitle>You</MessageTitle>
              <MessageContent>{content}</MessageContent>
            </MessageWrapper>
          </MessageUser>
        </>
      )}
    </MessageContainer>
  );
}
