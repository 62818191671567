import { WaveContainer } from "./styled";

const WavesAnimation = () => {
  return (
    <WaveContainer>
      <div></div>
      <div></div>
    </WaveContainer>
  );
};

export default WavesAnimation;
