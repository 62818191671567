import styled from "styled-components";

export const ChartContainer = styled.div``;
export const ChartWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  .apexcharts-tooltip {
    z-index: 9999;
    color: red;
  }
  svg {
    path {
      stroke-width: 0;
    }
  }
`;
export const ChartValue = styled.div`
  position: relative;
  min-height: 70px;
  @media screen and (max-width: 767px) {
    margin-bottom: 0;
  }
`;
export const ChartItem = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;
  img {
    width: 100%;
  }
  &.visible {
    opacity: 1;
  }
`;
export const ChartTitle = styled.p`
  color: #70B1E4;
  font-weight: 800;
  line-height: 1;
  font-size: 30px;
  margin-bottom: 15px;
  font-family: "Lost-Fish";
  @media screen and (max-width: 767px) {
    font-size: 30px;
    white-space: nowrap;
  }
`;
export const ChartNumber = styled.p`
  color: #70B1E4;
  font-weight: 800;
  line-height: 1;
  font-size: 25px;
  @media screen and (max-width: 767px) {
    font-size: 30px;
  }
`;