import { AirdropTitle } from "../Airdrop/styled";
import {
  AiChatBot,
  AiEmpty,
  ChatBotEmpty,
  ChatBotHeader,
  ChatBotLogo,
  ChatBotWindow,
  ChatBotWrapper,
  ChatContainer,
  ChatWrapper,
} from "./styled";
import title from "../../assets/Airdrop/airdrop_title.png";
import { useState } from "react";
import { useAccount } from "wagmi";
import Message from "./Message";
import { MessageWait } from "./Message/styled";
import Typewriter from "typewriter-effect";
import Input from "./Input";
import FishAnimation from "../../components/FishSwimming";
import Fish02 from "../Home/About/Fish02";
import Fish01 from "../Home/About/Fish01";

const Chat = () => {
  const [messages, setMessages] = useState<any>([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { address, isDisconnected } = useAccount();
  const handleSubmit = async () => {
    const prompt = {
      role: "user",
      content: input,
    };
    setIsLoading(true)
    setMessages([...messages, prompt]);

    await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: `Bearer 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`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [...messages, prompt],
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        const res = data.choices[0].message.content;
        setMessages((messages: any) => [
          ...messages,
          {
            role: "assistant",
            content: res,
          },
        ]);
        setInput("");
        setIsLoading(false)
      });
  };
  return (
    <ChatContainer>
      <Fish01 />
      <FishAnimation top="200px" bottom="unset" />
      <ChatWrapper>
        <AirdropTitle>
          <img src={title} alt="title" />
        </AirdropTitle>
        {address ? (
          <>
            <AiChatBot>
              <ChatBotWrapper message={messages.length > 0}>
                {messages.length > 0 ? (
                  <></>
                ) : (
                  <ChatBotHeader>
                    <ChatBotLogo></ChatBotLogo>
                  </ChatBotHeader>
                )}
                <ChatBotWindow message={messages.length > 0}>
                  {messages.length > 0 ? (
                    <>
                      {messages.map((el: any, i: any) => {
                        return (
                          <Message
                            key={i}
                            role={el.role}
                            content={el.content}
                            isLoading={isLoading}
                          />
                        );
                      })}
                      {isLoading && (
                        <MessageWait>
                          <Typewriter
                            options={{
                              strings: ["Please wait Turtie a few minutes..."],
                              autoStart: true,
                              loop: true,
                            }}
                          />
                        </MessageWait>
                      )}
                    </>
                  ) : (
                    <ChatBotEmpty></ChatBotEmpty>
                  )}
                </ChatBotWindow>
                <Input
                  isLoading={isLoading}
                  value={input}
                  onChange={(e: any) => setInput(e.target.value)}
                  onClick={input ? handleSubmit : undefined}
                />
              </ChatBotWrapper>
            </AiChatBot>
          </>
        ) : (
          <AiEmpty>
            <Typewriter
              options={{
                strings: ["Please connect your wallet..."],
                autoStart: true,
                loop: true,
              }}
            />
          </AiEmpty>
        )}
      </ChatWrapper>
      <Fish02 />
      <FishAnimation reverse top="unset" bottom="200px" />
    </ChatContainer>
  );
};

export default Chat;
