import styled from "styled-components";
import bg from '../../../assets/Tokenomic/bg.png'

export const TokenomicContainer = styled.div`
  position: relative;
  min-height: 100vh;
  background: url(${bg}) no-repeat center / cover;
  z-index: -2;
  @media screen and (min-width: 1440px) {
    background-size: 100% 100%;
  }
`;

export const TokenomicWrapper = styled.div`
  padding: 150px 20px 190px;
  @media screen and (max-width: 767px) {
    padding: 100px 0 0;
  }
`

export const TokenomicTitle = styled.h2`
  color: #70B1E4;
  text-align: center;
  font-family: "Lost-Fish";
  font-size: 36px;
  margin-bottom: 10px;
`
export const ChartImage = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 248px;
  width: 100%;
  img {
    width: 100%;
  }
`;
export const ChartBlock = styled.div`
  position: relative;
  min-height: 420px;
  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1063px;
    height: 642px;
    margin-left: -516px;
    margin-top: -316px;
    mix-blend-mode: lighten;
    pointer-events: none;
    z-index: 5;
  }
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 786px;
    height: 786px;
    margin-left: -386px;
    margin-top: -436px;
    mix-blend-mode: lighten;
    z-index: 5;
    pointer-events: none;
  }
  @media screen and (max-width: 450px) {
    min-height: 450px;
  }
`;
export const TokenomicValue = styled.div`
  position: relative;
  max-width: 636px;
  width: 100%;
  margin: -48px auto 0;
  z-index: 5;
  img {
    width: 100%;
  }
`;