import { FishContainer } from "../Fish01/styled";

const Fish02 = () => {
  return (
    <FishContainer>
      <svg
        width="99px"
        height="55px"
        viewBox="0 0 99 55"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        id="fish3"
      >
        <title>fish-2</title>
        <desc>Created with Sketch.</desc>
        <defs></defs>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="Artboard" transform="translate(-145.000000, -273.000000)">
            <g
              id="fish-3"
              transform="translate(192.500000, 300.500000) scale(-1, 1) translate(-192.500000, -300.500000) translate(139.000000, 259.000000)"
            >
              <circle
                id="bubble-1"
                fill="#FFFFFF"
                opacity="0.5"
                cx="9"
                cy="41"
                r="5"
              ></circle>
              <circle
                id="bubble-2"
                fill="#FFFFFF"
                opacity="0.5"
                cx="9"
                cy="41"
                r="5"
              ></circle>
              <circle
                id="bubble-3"
                fill="#FFFFFF"
                opacity="0.5"
                cx="9"
                cy="41"
                r="5"
              ></circle>
              <path
                d="M33.4863938,16.3108983 L64.7796385,12.1302612 L64.7796385,12.1302612 C67.5167446,11.7645961 70.0320377,13.6870289 70.3977028,16.4241351 C70.4564107,16.8635798 70.4564107,17.3088809 70.3977028,17.7483256 L66.2170658,49.0415702 L66.2170658,49.0415702 C65.022152,57.9858358 57.9858358,65.022152 49.0415702,66.2170658 L17.7483256,70.3977028 L17.7483256,70.3977028 C15.0112194,70.7633679 12.4959263,68.8409351 12.1302612,66.1038289 C12.0715534,65.6643842 12.0715534,65.2190832 12.1302612,64.7796385 L16.3108983,33.4863938 L16.3108983,33.4863938 C17.505812,24.5421283 24.5421283,17.505812 33.4863938,16.3108983 Z"
                id="Rectangle"
                fill="#99D4F9"
                transform="translate(41.263982, 41.263982) rotate(45.000000) translate(-41.263982, -41.263982) "
              ></path>
              <path
                d="M47.1884833,19.2539388 L69.4540891,16.2793538 L66.4795041,38.5449595 L66.4795041,38.5449595 C65.2845903,47.489225 58.248274,54.5255413 49.3040085,55.7204551 L33.5227463,57.8287609 L33.5227463,57.8287609 C30.7856401,58.194426 28.2703471,56.2719932 27.904682,53.534887 C27.8459741,53.0954423 27.8459741,52.6501412 27.904682,52.2106965 L30.0129878,36.4294343 L30.0129878,36.4294343 C31.2079015,27.4851688 38.2442178,20.4488525 47.1884833,19.2539388 Z"
                id="Rectangle-Copy-17"
                fill="#5C98C0"
                transform="translate(48.657370, 37.076363) rotate(45.000000) translate(-48.657370, -37.076363) "
              ></path>
              <path
                d="M92.8430713,33.0359353 L94.1681096,33.7606228 L94.1681096,33.7606228 C97.523905,35.5959682 100.283038,38.3551015 102.118384,41.7108969 L102.843071,43.0359353 L102.843071,43.0359353 C104.000724,45.152619 103.223276,47.8069931 101.106592,48.9646459 C100.463863,49.3161659 99.7430518,49.5004013 99.0104764,49.5004013 L91.3786052,49.5004013 L91.3786052,49.5004013 C88.6171814,49.5004013 86.3786052,47.2618251 86.3786052,44.5004013 L86.3786052,36.8685301 L86.3786052,36.8685301 C86.3786052,34.4559569 88.3343823,32.5001798 90.7469555,32.5001798 C91.4795309,32.5001798 92.2003425,32.6844152 92.8430713,33.0359353 Z"
                id="Rectangle-Copy"
                fill="#99D4F9"
                transform="translate(94.879127, 41.000291) rotate(45.000000) translate(-94.879127, -41.000291) "
              ></path>
              <path
                d="M85.0751958,33.7576545 L89.1464594,35.9843025 L89.1464594,35.9843025 C91.8047957,37.4381949 93.9904785,39.6238777 95.4443709,42.282214 L95.4443709,42.282214 L95.4443709,42.282214 C96.1734504,43.6152827 95.6838204,45.2869841 94.3507517,46.0160635 C93.9459668,46.2374478 93.4920061,46.3534776 93.0306366,46.3534776 L90.0751958,46.3534776 L90.0751958,46.3534776 C87.313772,46.3534776 85.0751958,44.1149013 85.0751958,41.3534776 L85.0751958,33.7576545 Z"
                id="Rectangle-Copy"
                fill="#5C98C0"
                transform="translate(90.428749, 41.000183) rotate(45.000000) translate(-90.428749, -41.000183) "
              ></path>
              <circle id="Oval" fill="#3B3838" cx="19" cy="37" r="5"></circle>
            </g>
          </g>
        </g>
      </svg>
    </FishContainer>
  );
};

export default Fish02;
