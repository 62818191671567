import styled from "styled-components";

// Image
import keyvisualBg from '../../../assets/Keyvisual/keyvisual_bg.png'
import { FishContainer } from "../../../components/FishSwimming";

export const KeyvisualContainer = styled.div`
  position: relative;
  padding-top: 170px;
  min-height: 80vh;
  background: url(${keyvisualBg}) no-repeat center / cover;
  ${FishContainer} {
    mix-blend-mode: plus-lighter;
  }
  @media screen and (min-width: 1920px) {
    background-size: 100% 100%;
  }
  @media screen and (max-width: 767px) {
    padding-top: 120px;
  }
`
export const KeyvisualWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`
export const KeyvisualTitle = styled.h1`
  text-align: center;
  font-family: "Lost-Fish";
  font-size: 48px;
  background: linear-gradient(180deg, #BEE5FF 0%, #1F4FA8 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 13px;
`
export const KeyvisualText = styled.p`
  color: #F5F9FF;
  text-align: center;
  font-family: "PP-Pangaia-Medium";
  font-size: 14px;
  font-weight: 500;
  max-width: 500px;
  margin: 0 auto;
`