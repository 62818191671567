import { AirdropTitle } from "../Airdrop/styled";
import {
  ButtonNav,
  MarketplaceContainer,
  MarketplaceDescribe,
  MarketplaceInfo,
  MarketplaceSearch,
  MarketplaceWrapper,
  SearchBlock,
  SwiperBlock,
  SwiperWrapper,
} from "./styled";
import title from "../../assets/Airdrop/airdrop_title.png";
import search_icon from "../../assets/Marketplace/search_icon.png";
import { ConnectWalletButton } from "../../components/Common/ConnectWallet/styled";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import icon_nav from "../../assets/Marketplace/button_nav.png";
import nft_2 from "../../assets/Marketplace/nft_2.png";
import nft_1 from "../../assets/Marketplace/nft_1.png";
import nft_3 from "../../assets/Marketplace/nft_3.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import FishAnimation from "../../components/FishSwimming";
import Fish01 from "../Home/About/Fish01";
import Fish02 from "../Home/About/Fish02";

const Marketplace = () => {
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
  return (
    <MarketplaceContainer>
      <Fish01 />
      <FishAnimation top="200px" bottom="unset" />
      <MarketplaceWrapper>
        <AirdropTitle>
          <img src={title} alt="title" />
        </AirdropTitle>
        <MarketplaceDescribe>
          Turtie NFTs offer more than ownership—they unlock exclusive in-game
          content,
          <br className="sm" /> trading opportunities, and staking rewards.
          <br className="sm" /> Dive into the world of Turtie where your NFTs
          come to life!
        </MarketplaceDescribe>
        <MarketplaceInfo>
          <div>
            <p>Floor Price</p>
            <p>0.00</p>
          </div>
          <div>
            <p>Best Price</p>
            <p>0.00</p>
          </div>
          <div>
            <p>Total Volume</p>
            <p>0.00</p>
          </div>
          <div>
            <p>Owner</p>
            <p>0.00</p>
          </div>
          <div>
            <p>Listed</p>
            <p>0.00</p>
          </div>
        </MarketplaceInfo>
        <MarketplaceSearch>
          <SearchBlock>
            <img src={search_icon} alt="icon" />
            <input type="text" placeholder="Search Item" />
          </SearchBlock>
          <ConnectWalletButton>
            <span
              style={{
                marginTop: "5px",
              }}
            >
              Search
            </span>
          </ConnectWalletButton>
        </MarketplaceSearch>
        <SwiperWrapper>
          <Swiper
            navigation={{ prevEl, nextEl }}
            slidesPerView={3}
            // pagination
            modules={[Pagination, Navigation]}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1100: {
                slidesPerView: 3,
              },
            }}
          >
            {swiperData.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <SwiperBlock>
                    <figure>
                      <img src={item.img} alt="img" />
                    </figure>
                  </SwiperBlock>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <ButtonNav className="prev" ref={(node:any) => setPrevEl(node)}>
            <img src={icon_nav} alt="icon" />
          </ButtonNav>
          <ButtonNav className="next" ref={(node:any) => setNextEl(node)}>
            <img src={icon_nav} alt="icon" />
          </ButtonNav>
        </SwiperWrapper>
      </MarketplaceWrapper>
      <Fish02 />
      <FishAnimation reverse top="unset" bottom="200px" />
    </MarketplaceContainer>
  );
};

const swiperData = [
  {
    img: nft_1,
  },
  {
    img: nft_2,
  },
  {
    img: nft_3,
  },
  {
    img: nft_1,
  },
];

export default Marketplace;
