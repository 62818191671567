import styled from "styled-components";

// Image
import ButtonBG from '../../../assets/Common/ConnectWallet/button_bg.png'

export const ConnectWalletButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 11px;
  border-radius: 8px;
  width: 126px;
  height: 58px;
  z-index: 1;
  font-family: 'PP-Pangaia-Medium-Bold';
  font-weight: 700;
  color: #000;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  a {
    color: unset;
  }
  span {
    position: relative;
    top: -1px;
  }
  &::before {
    background: url(${ButtonBG}) center / contain no-repeat;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  @media screen and (max-width: 767px) {
    font-size: 15px;
    width: 100px;
    height: 45px;
    padding: 3px 10px 7px;
    span {
      top: 0;
    }
  }
`;
