import styled from "styled-components";
import overlay from "../../../assets/Partner/overlay.png";
import bg from "../../../assets/Partner/bg.png";
import turtle_1 from "../../../assets/Partner/turtle_1.png";
import turtle_2 from "../../../assets/Partner/turtle_2.png";
import buttonbg from "../../../assets/Common/ConnectWallet/button_bg.png";

export const PartnerContainer = styled.div`
  position: relative;
  @media screen and (max-width: 767px) {
    overflow: hidden;
  }
`;
export const PartnerWrapper = styled.div`
  position: relative;
  max-width: 785px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: -90px;
    max-width: 1100px;
    width: 100%;
    height: 644px;
    background: url(${overlay}) no-repeat center / 100% auto;
    pointer-events: none;
  }
  @media screen and (max-width: 767px) {
    padding: 0;
    &:before {
      top: -100px;
      width: 130%;
      left: -60px;
    }
  }
`;
export const PartnerInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 468px;
  background: url(${bg}) no-repeat center / 100% auto;
  > div {
    max-width: 400px;
    overflow: hidden;
    padding-top: 27px;
  }
  @media screen and (max-width: 767px) {
    > div {
      padding-top: 11px;
      max-width: 220px;
    }
  }
`;
export const PartnerTurtle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  &::before,
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    background: no-repeat center / 100% auto;
  }
  &::before {
    left: 0;
    background-image: url(${turtle_1});
    width: 136px;
    height: 266px;
  }
  &::after {
    right: 0;
    background-image: url(${turtle_2});
    width: 146px;
    height: 204px;
  }
  @media screen and (max-width: 767px) {
    &:before {
      width: 80px;
    }
    &:after {
      width: 90px;
    }
  }
`;
export const PartnerFish = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  img {
    position: absolute;
    &:nth-child(1) {
      top: 120px;
      left: 0;
    }
    &:nth-child(2) {
      bottom: 25px;
      right: 0;
    }
  }
`;
export const PartnerTitle = styled.h2`
  color: #70b1e4;
  text-align: center;
  font-family: "Lost-Fish";
  font-size: 36px;
  margin-bottom: 10px;
  @media screen and (max-width: 767px) {
    font-size: 30px;
  }
`;

export const MarqueeItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 126px;
  width: 100%;
  min-height: 68px;
  margin: 0 5px;
  background: url(${buttonbg}) no-repeat center / 100% 100%;
  img {
    width: 65%;
  }
  @media screen and (max-width: 767px) {
    max-width: 80px;
    min-height: 30px;
  }
`;
